<template>
  <div class="ui grid container-5" id="dwnldapp">
    <div class="two column row stackable">
      <div class="column div-1">
        <div class="left">
          <h1>Download<span> the App</span></h1>

          <p>Start making your life easier by having your own TayoCash e-wallet. Shop, pay bills, send money, and do more. Click below or scan the QR to download.</p>

          <div class="download-white">
            <a href="https://apps.apple.com/ph/app/tayocash/id1547566625" class="apple" target="_blank">
              <img src="@/assets/apple-black.svg" alt="apple store">
              <div class="text">
                Download on the
                <span>App Store</span>
              </div>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.tayocash" target="_blank" class="android">
              <img src="@/assets/playstore.svg" alt="play store">
              <div class="text">
                GET IT ON
                <span>Google Play</span>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="mid-phone"></div>

      <!-- <img class="mid-phone" src="@/assets/group2.svg" alt="Tayocash"> -->

      <div class="column div-2">
        <div class="right">
          <h1>Register <span>&</span> Cash In</h1>

          <p>Want to know how to begin using your TayoCash e-wallet? Just click below to learn more.</p>

          <div class="register">
            <a class="yellow" href="/faq/gettingstarted">HOW TO REGISTER</a>
            <a class="white" href="/faq/services">HOW TO CASH IN</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content5'
}
</script>

<style scoped lang='scss'></style>
